@import './base/bootstrap.scss';
@import './base/bootstrap-extended.scss';
@import './base/colors.scss';
@import './base/components.scss';

// Themes
@import './base/themes/dark-layout.scss';
@import './base/themes/bordered-layout.scss';
@import './base/themes/semi-dark-layout.scss';
// @import './base/custom-rtl.scss';

// React Specific
@import 'react/index';
@import 'base/plugins/forms/form-file-uploader.scss';

.header-navbar .navbar-container ul.navbar-nav li > div.nav-link {
    color: #6e6b7b;
    padding: 0 0.5rem;
    position: relative;
}

.header-navbar .navbar-container ul.navbar-nav li div.dropdown-user-link .user-nav {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    float: left;
    margin-right: 0.8rem;
}

.header-navbar .navbar-container ul.navbar-nav li div.dropdown-user-link .user-name {
    display: inline-block;
    margin-bottom: 0.435rem;
    margin-left: 0.2rem;
}

.header-navbar .navbar-container ul.navbar-nav li div.dropdown-user-link .user-status {
    font-size: smaller;
}
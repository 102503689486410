/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.navbar-header {
  height: 6.45rem !important;
}

.leaflet-map {
  height: 100%;
  width: 100%;
}

.but_plus {
  width: 41px;
  height: 41px;
  background: #34e0ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 5px #34e0ff4d;
  border-radius: 5px;
  border: none;
  font: normal normal 600 18px/41px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  cursor: pointer;
}

.ReactTags__tags {
  padding: 0rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  display: table;
  width: 100%;
  padding-bottom: 10px;

  .ReactTags__tagInputField {
    border: none;
    float: left;
    height: 36px;
    padding-left: 10px;
  }

  .ReactTags__tagInputField::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
    opacity: 0;
    /* Firefox */
  }

  .ReactTags__tagInputField:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
  }

  .ReactTags__tagInputField::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #fff;
  }

  .ReactTags__tagInputField:focus {
    outline: none;
  }

  .ReactTags__tag {
    height: 27px;
    float: left;
    background: #7367f0 0% 0% no-repeat padding-box;
    padding-left: 10px;
    list-style: 24px;
    border-radius: 5px;
    text-align: center;
    letter-spacing: 0px;
    color: #ffffff;
    margin-top: 10px;
    margin-right: 10px;
  }

  .ReactTags__remove {
    background: transparent;
    border: none;
    color: #fff;
    height: 27px;
    width: 27px;
  }

  .ReactTags__remove:focus {
    outline: none;
  }
}

.my_dropzone {
  cursor: pointer;
  background-color: #f8f8f8;
  border: 1px dashed #7367f0;
  border-radius: 0.357rem;
  height: 200px;
  width: 100%;
  margin: 20px 0;
  color: #7367f0;
  svg {
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.card-login-custom {
  display: flex;
  align-items: center;
  max-width: 1096px;
  width: 80%;
  .image-login {
    width: 100%;
    height: 100%;
    aspect-ratio: 12/9;
    object-fit: cover;
    object-position: center;
  }
}

.detail-users-container {
  display: flex;
  gap: 0.5rem;
  @media (width < 1018px) {
    flex-direction: column;
  }

  .base-data-user {
    height: 190px;
    background-color: #fff;
    border-radius: 5px;
    display: flex;

    @media (width < 1018px) {
      flex-direction: column;
      height: auto;
      padding: 2rem;
    }

    .initial-container {
      display: flex;
      gap: 1rem;
      @media (width < 1018px) {
        gap: 3rem;
      }
    }
    .square-initials {
      width: 100px;
      height: 100px;
      color: #ff9f43;
      border-radius: 5px;
      background-color: #fff4e9;
      font-size: 2.5rem;
      font-weight: bolder;
      display: grid;
      place-content: center;
    }
    .text-name {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0px;
      color: #6d6b7b;
    }
    .text-email {
      font: normal normal medium 14px/59px Montserrat;
      letter-spacing: 0px;
      color: #6d6b7b;
    }
    .btn-edit {
      font-weight: bold;
      background-color: #7367f0 !important;
    }
    .btn-delete {
      font-weight: bold;
      color: red !important;
      border: 1px solid red !important;
      background-color: #fff !important;
    }
    .square-dollar {
      background-color: #7367f030;
      width: 40px;
      height: 40px;
      display: grid;
      place-content: center;
      border-radius: 5px;
    }
    .text-value-dollar {
      text-align: left;
      font: normal normal bold 17px/19px Montserrat;
      letter-spacing: 0px;
      color: #58586a;
    }

    .part-two-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 5rem;
      padding-right: 5rem;
      gap: 1rem;
      @media (width < 1018px) {
        padding-left: 1rem;
        margin-top: 1rem;
      }
    }
    .items-part-two {
      display: flex;
      align-items: center;
      padding-left: 4rem;
      padding-right: 4rem;
      min-width: 400px;
      max-width: 600px;

      @media (width < 1018px) {
        padding: 0;
      }
      .flex-1-pt {
        flex: 1;
      }
      .flex-4-pt {
        flex: 4;
      }
    }
  }
}

.sidebar-details-payments-items {
  display: flex;
  .title-item-details-payments {
    font: normal normal medium 13px/16px Montserrat;
    color: #6a6b89;
  }
  .subtitle-item-details-payments {
    text-align: left;
    font: normal normal medium 13px/16px Montserrat;
    letter-spacing: 0px;
    color: #b9b9c3;
    opacity: 1;
  }
}

.section-date {
  display: flex;
  font: normal normal medium 13px/16px Montserrat;

  .text-title {
    color: #6a6b89;
    margin-bottom: 0.5rem;
  }
  .text-subtitle {
    color: #b9b9c3;
  }
}

.section-comments {
  display: flex;
  font: normal normal medium 13px/16px Montserrat;

  .text-title {
    color: #6a6b89;
    margin-bottom: 0.5rem;
  }
  .text-subtitle {
    color: #b9b9c3;
  }
}

.card-info-person {
  display: flex;
  gap: 1rem;
  padding: 0.5rem;
  margin-top: 1rem;
  .initials-name {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    display: grid;
    place-content: center;
    font-weight: bold;
    font-size: 1.2rem;
    background-color: #e0fbec;
    color: #62d76c;
  }
  .text-container {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }

  .text-name {
    font-weight: 700;
    font-size: 17px;
    color: #7e6cfb;
  }

  .text-email {
    color: #b3b3b3;
  }
}

.account-recharge {
  height: 240px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0.5rem;
  background-color: #fff;
  .title-form {
    font: normal normal 600 18px/22px Montserrat;
    color: #6d6b7b;
  }
}

.title-header-custom-table {
  border: 1px solid red;
  height: 10rem;
}

.image-payment {
  width: 100%;
  height: 115px;
  margin-top: 1rem;
  object-fit: cover;
  object-position: center;
}

.background-help {
  background-image: url('../images/backgrounds/backgroundHelp.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.help-first-container {
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e8e7f750;

  .title-help {
    font: normal normal 600 17px/59px Montserrat;
    letter-spacing: 0px;
    color: #423b3b;
  }

  .subtitle-help {
    font: normal normal medium 13px/59px Montserrat;
    color: #434343;
  }

  .custom-search {
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: #fff;
    padding: 0.3rem;
    height: 2.5rem;
    width: 100%;

    input {
      border: none !important;
      outline: none;
      height: 100%;
    }
  }
}

.help-second-container {
  margin-top: 2rem;
  .lateral-menu {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 2rem 2rem 0rem;
  }
  .item-menu {
    height: 3rem;
    border-radius: 5px;
    padding-left: 2rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.2rem;
    font-weight: 600;
    color: #868295;
    cursor: pointer;
  }

  .active {
    background-color: #7367f0;
    color: #fff;
    box-shadow: 1px 1px 5px #7367f0;
  }
  .show-answer-container {
    .header-show {
      display: flex;
      gap: 0.5rem;
      font-weight: 600;
      .subtitle-show {
        font-size: 12px;
        font-weight: 500;
      }
      .square-icon {
        border-radius: 5px;
        width: 40px;
        height: 40px;
        display: grid;
        place-content: center;
        background-color: #e8e7f7;
      }
    }

    .sumary-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 0.6rem 0.6rem 0.6rem 0;
    }
    .sumary {
      list-style: none;
      cursor: pointer;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      height: 4rem;
      display: flex;
      align-items: center;
      background-color: #fff;
      position: relative;
      user-select: none;
      padding: 1rem;
    }

    .summary::-webkit-details-marker {
      display: none;
    }

    .answer {
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      background-color: #fff;
      user-select: none;
      padding: 1rem;
    }
    .arrow {
      position: absolute;
      right: 20px;
    }
  }
  .image-girl {
    width: 80%;
    margin-top: 1rem;
    object-fit: cover;
    object-position: center;
    align-self: center;
  }
  .title-continue {
    font: normal normal 600 17px/59px Montserrat;
    letter-spacing: 0px;
    color: #423b3b;
  }

  .subtitle-continue {
    font: normal normal medium 13px/59px Montserrat;
    color: #434343;
  }
}
.faq-contact-card {
  height: 10rem;
  border-radius: 5px;
  background-color: #eaeaeb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 500;
  padding: 1rem;
  .square-icon {
    height: 40px;
    width: 40px;
    border-radius: 5px;
    background-color: #dcdbeb;
    display: grid;
    place-content: center;
  }
}

.icon-dasboard-custom {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  display: grid;
  place-content: center;
  margin-right: 0.5rem;
}
.purple-bg {
  background-color: #766af030;
}

.checkbox-admin:checked {
  background-color: #a39df6;
  border: none;
  outline: none;
}

.card-settings-general {
  min-height: 200px;
  background: #fff;
  box-shadow: 0px 0px 14px #00000019;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 2rem;
  color: #6d6b7b;
  .label-title {
    font: normal normal 600 20px/24px Montserrat;
  }

  .label-title-select {
    font: normal normal medium 14px/59px Montserrat;
    margin-bottom: 0.5rem;
  }
  .custom-select-settings {
    display: flex;
    gap: 2rem;
  }
}

.custom-select-phone {
  display: flex;
  border: 1px solid #d4d4d4 !important;
  border-radius: 5px !important;

  .select-phone {
    border: 1px solid #ffffff00 !important;
  }
}

.text-error-form {
  border-radius: 5px;
  border: 1px solid red !important;
}
.text-error-form:focus {
  border-radius: 5px;
  border: 1px solid red !important;
}

.ReactTags__tags {
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  overflow-y: hidden;
}

.ReactTags__tag {
  margin: 4px; /* Espacio entre los tags */
  max-width: 100%; /* Evita que los tags sobrepasen su contenedor */
}

.ReactTags__tags::-webkit-scrollbar {
  width: 0.5em; /* Ancho del scrollbar para navegadores basados en WebKit */
}

.ReactTags__tags::-webkit-scrollbar-thumb {
  background-color: transparent; /* Color del thumb del scrollbar */
}

.ReactTags__tags::-webkit-scrollbar-track {
  background-color: transparent; /* Color de la pista del scrollbar */
}

.ReactTags__tags {
  scrollbar-width: thin; /* Ancho del scrollbar para navegadores basados en Firefox */
}

.ReactTags__tags::-moz-scrollbar {
  width: 0.5em; /* Ancho del scrollbar para navegadores basados en Firefox */
}

.ReactTags__tags::-moz-scrollbar-thumb {
  background-color: transparent; /* Color del thumb del scrollbar */
}

.ReactTags__tags::-moz-scrollbar-track {
  background-color: transparent; /* Color de la pista del scrollbar */
}

.resize-none {
  resize: none;
}

.customUploadImages {
  width: 100%;
  height: 120px;
  border: 2px dashed #7367f0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  position: relative;

  .topLayer {
    position: absolute;
    top: 20px;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font: normal normal bold 14px/59px Montserrat;
    letter-spacing: 0px;
    color: #7367f0;
    opacity: 1;
    // z-index: 1;
  }

  .dropText {
    font: normal normal normal 13px/59px Montserrat;
  }

  .isTrack {
    position: absolute;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    font-size: 50px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #7367f0;

    & p {
      margin-top: 1rem;
      font-size: 16px;
      text-align: center;
      padding: 0.5rem;
      word-break: break-all;
    }
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    border-radius: 10px;
    position: absolute;
    // z-index: 99;
    background-color: #fff;
  }

  & input {
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 100;
  }
}

.rdt_Table{
  min-height: 400px;
}